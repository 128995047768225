/*
 * @Author: 智客云网络科技
 * @Date: 2021-03-31 12:43:04 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-05-25 11:29:03
 * @Description: 工具类
 * @FilePath: \src\utils\index.js
 */

import watermark from "./watermark"; //水印
import { Dialog, Toast } from "vant";
import router from "@/router";
import { useRoute } from "vue-router";
import system_config from "@/config";

//定义全局工具方法
const Utils = {
  /**
   * 从url中查询到指定参数值
   * @param {string} url url 不出入默认自动获取
   * @param {string} name 键名
   * @returns
   */
  urlgetval: (url = "", name) => {
    url = url || window.location.search.substring(1);
    let vars = url.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (pair[0] === name) {
        return pair[1];
      }
    }
    return undefined;
  },

  /**
   * 判断一个变量是否为null
   * @param {*} val
   * @returns boolean
   */
  isNull: (val) => {
    return ["null", "undefined", "", null, undefined].indexOf(val) !== -1;
  },

  // 将时间戳转化为指定时间
  // way: 方式 (1=年月日, 2=年月日时分秒) 默认1,  也可以指定格式 yyyy-MM-dd HH:mm:ss
  /**
   * 将时间戳转化为指定时间
   * @param {Date} inputTime 时间
   * @param {*} way 方式(1=年月日, 2=年月日时分秒) 默认1 也可以指定格式 yyyy-MM-dd HH:mm:ss
   * @return string
   */
  forDate: (way, inputTime) => {
    if (Utils.isNull(inputTime) === true) {
      inputTime = new Date();
    }
    let date = new Date(inputTime);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    way = way || 1;
    // way == 1  年月日
    if (way === 1) {
      return y + "-" + m + "-" + d;
    }
    // way == 1  年月日时分秒
    if (way === 2) {
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    }
    // way == 具体格式   标准格式: yyyy-MM-dd HH:mm:ss
    if (typeof way == "string") {
      return way
        .replace("yyyy", y)
        .replace("MM", m)
        .replace("dd", d)
        .replace("HH", h)
        .replace("mm", minute)
        .replace("ss", second);
    }
    return y + "-" + m + "-" + d;
  },

  /**
   * 将时间转化为 多长时间以前 如: 3小时前
   * @param {*} d 时间
   * @returns
   */
  forDate2: (d) => {
    let hou = "前";

    if (d == null || d === "") {
      return "";
    }

    let d2 = new Date();
    let timestamp = new Date(d).getTime() - 1000;
    let mistiming = Math.round((d2 - timestamp) / 1000);
    if (mistiming < 0) {
      mistiming = 0 - mistiming;
      hou = "后";
    }
    let arrr = ["年", "月", "周", "天", "小时", "分钟", "秒"];
    let arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
    for (let i = 0; i < arrn.length; i++) {
      let inm = Math.floor(mistiming / arrn[i]);
      if (inm !== 0) {
        return inm + arrr[i] + hou;
      }
    }
  },

  /**
   * 综合以上两种方式, 进行格式化 小于24小时的走forDate2, 否则forDat
   * @param {*} d
   * @param {*} way
   * @returns
   */
  forDate3: (d, way) => {
    if (d == null || d === "") {
      return "";
    }
    let cha = new Date().getTime() - new Date(d).getTime();
    cha = cha > 0 ? cha : 0 - cha;
    if (cha < 86400 * 1000) {
      return Utils.forDate2(d);
    }
    return Utils.forDate(way, d);
  },

  /**
   * 返回时间差, 此格式数组: [x, x, x, 天, 时, 分, 秒]
   * @param {*} small_time 开始时间
   * @param {*} big_time 结束时间
   * @returns
   */
  getSJC: (small_time, big_time) => {
    let date1 = new Date(small_time); //开始时间
    let date2 = new Date(big_time); //结束时间
    let date3 = date2.getTime() - date1.getTime(); //时间差秒
    //计算出相差天数
    let days = Math.floor(date3 / (24 * 3600 * 1000));

    //计算出小时数
    let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    let hours = Math.floor(leave1 / (3600 * 1000));

    //计算相差分钟数
    let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2 / (60 * 1000));

    //计算相差秒数
    let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
    let seconds = Math.round(leave3 / 1000);

    // 返回数组
    return [0, 0, 0, days, hours, minutes, seconds];
  },

  /**
   * 将日期, 加上指定天数
   * @param {*} d 日期
   * @param {*} n 天数
   * @returns
   */
  dateAdd: (d, n) => {
    let s = new Date(d).getTime();
    s += 86400000 * n;
    return new Date(s);
  },

  /**
   * 截取指定长度字符 默认10
   * @param {string} str 字符串
   * @param {int} length 截断长度
   * @returns
   */
  maxLength: (str, length) => {
    length = length || 50;
    if (!str) {
      return "";
    }
    return str.length > length ? str.substring(0, length) + " ..." : str;
  },

  /**
   * 过滤掉标签
   * @param {string} str 字符串
   * @returns
   */
  filterTags: (str) => {
    if (!str) {
      return "";
    }
    return str.replace(/<[^>]+>/g, "");
  },

  /**
   * 获得一段文字中所有图片的路径
   * @param {*} str
   * @returns
   */
  getSrcList: (str) => {
    try {
      let imgReg = /<img.*?(?:>|\/>)/gi; //匹配图片（g表示匹配所有结果i表示区分大小写）
      // let srcReg = /src=[\\\\'\\\\"]?([^\\\\'\\\\"]*)[\\\\'\\\\"]?/i; //匹配src属性
      let srcReg = /src=[\\'"]?([^\\'"]*)[\\'"]?/i; //匹配src属性
      let arr = str.match(imgReg); // 图片数组
      let srcList = [];
      for (let i = 0; i < arr.length; i++) {
        let src = arr[i].match(srcReg);
        srcList.push(src[1]);
      }
      return srcList;
    } catch (e) {
      return [];
    }
  },

  /**
   * 无精度损失的乘法
   * @param {*} arg1
   * @param {*} arg2
   * @returns
   */
  accMul: (arg1, arg2) => {
    let m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString(),
      t;

    t = s1.split(".");
    // 判断有没有小数位，避免出错
    if (t[1]) {
      m += t[1].length;
    }

    t = s2.split(".");
    if (t[1]) {
      m += t[1].length;
    }

    return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
      Math.pow(10, m)
    );
  },

  /**
   * 正则验证是否为手机号
   * @param {*} str 手机号
   * @returns
   */
  isPhone: (str) => {
    str = str + "";
    return /^1[34578]\d{9}$/.test(str);
  },

  /**
   * 产生随机字符串
   * @param {*} len 长度 默认32
   * @returns
   */
  randomString: (len) => {
    len = len || 32;
    let $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let maxPos = $chars.length;
    let str = "";
    for (let i = 0; i < len; i++) {
      str += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return str;
  },

  /**
   * 复制指定文本
   * 注意 这个方法基于Clipboard API实现
   * 生产环境下需要HTTPS协议才能正常使用
   * @param {string} str 待复制文本
   */
  copyText: (str) => {
    //获取 Clipboard 对象
    const clipboardObj = navigator.clipboard;
    if (clipboardObj === undefined) {
      Utils.msg("您当前的浏览器暂不支持一键复制！"); //输出提示
      return;
    }
    //执行写入操作
    clipboardObj.writeText(str).then((r) => {
      console.log(r);
    });
  },

  // 回到顶部
  goTop: () => {
    function smoothscroll() {
      let currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 5);
      }
    }

    smoothscroll();
  },

  //设置网页标题
  setDocumentTitle: (title) => {
    const app_title = system_config.app_name;
    document.querySelector("title").innerHTML = app_title + " - " + title;
  },

  //获取当前页面url
  getUrl: () => {
    return window.location.href.match(/^[^\\#?]+/)[0];
  },

  //判断是否在微信浏览器打开
  assertWeChat: () => {
    // 首先判断是否使用微信内，因为微信JS-SDK只有在微信环境下才有用
    const environmental = window.navigator.userAgent.toLowerCase();
    return environmental.match(/MicroMessenger/i) != null;
  },

  /**
   * 去除json对象中的空值
   * @param {*} obj json对象
   * @returns
   */
  removeNull: (obj) => {
    let newObj = {};
    if (obj !== undefined && obj != null) {
      for (let key in obj) {
        if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
          //
        } else {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  },

  /**
   * JSON 浅拷贝, 返回拷贝后的obj
   * @param {*} obj
   * @returns
   */
  copyJSON: (obj) => {
    if (obj === null || obj === undefined) {
      return obj;
    }
    let new_obj = {};
    for (let key in obj) {
      new_obj[key] = obj[key];
    }
    return new_obj;
  },

  /**
   * json合并, 将 defaulet配置项 转移到 user配置项里 并返回 user配置项
   * @param {*} userOption
   * @param {*} defaultOption
   * @returns
   */
  extendJson: (userOption, defaultOption) => {
    if (!userOption) {
      return defaultOption;
    }
    for (let key in defaultOption) {
      if (userOption[key] === undefined) {
        userOption[key] = defaultOption[key];
      } else if (userOption[key] == null) {
        // console.log();
      } else if (typeof userOption[key] == "object") {
        Utils.extendJson(userOption[key], defaultOption[key]); //深度匹配
      }
    }
    return userOption;
  },

  //返回一个字符串中是否有英文字母
  is_have_en: (str) => {
    let reg = /[a-z]/i;
    return reg.test(str); //true,说明有英文字母
  },

  /******************** 数组操作 ********************/

  /**
   * 为集合的每一项添加指定属性
   * @param {*} list
   * @param name
   * @param value
   * @returns
   */
  listAddAttr: (list, name, value) => {
    list.forEach((ts) => {
      ts[name] = value;
    });
    return list;
  },

  /**
   * 从数组里获取数据,根据指定数据
   * @param {*} arr 数组
   * @param {*} prop 数据键名
   * @param {*} value 标定数据
   * @returns
   */
  arrayGet: (arr, prop, value) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return arr[i];
      }
    }
    return null;
  },

  /**
   * 根据指定数据从数组里获取index
   * @param {*} arr 数组
   * @param {*} prop 数据键名
   * @param {*} value 标定数据
   * @returns
   */
  arrayGetIndex: (arr, prop, value) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return null;
  },

  /**
   * 从数组删除指定记录
   * @param {*} arr 数组
   * @param {*} item 记录值
   */
  arrayDelete: (arr, item) => {
    let index = arr.indexOf(item);
    if (index > -1) {
      arr.splice(index, 1);
    }
  },

  /**
   * 从数组删除指定id的记录
   * @param {*} arr 数组
   * @param {*} id 记录id
   */
  arrayDeleteById: (arr, id) => {
    let item = Utils.arrayGet(arr, "id", id);
    Utils.arrayDelete(arr, item);
  },

  /**
   * 将数组B添加到数组A的开头
   * @param {*} arrA
   * @param {*} arrB
   * @returns
   */
  unshiftArray: (arrA, arrB) => {
    if (arrB) {
      arrB.reverse().forEach(function (ts) {
        arrA.unshift(ts);
      });
    }
    return arrA;
  },

  /**
   * 将数组B添加到数组A的末尾
   * @param {*} arrA
   * @param {*} arrB
   * @returns
   */
  pushArray: (arrA, arrB) => {
    if (arrB) {
      arrB.forEach(function (ts) {
        arrA.push(ts);
      });
    }
    return arrA;
  },

  /******************** 弹窗提示 ********************/

  /**
   * 消息提示
   * @param {string} msg 消息文字
   * @param {number} duration 展示时间
   * @param {Function} onOpened 展示后回调
   * @param {Function} onClose 结束后回调
   * @param overlay
   */
  msg: (
    msg = "消息提示",
    duration = 2000,
    onOpened = null,
    onClose = null,
    overlay = true
  ) => {
    setTimeout(() => {
      Toast({
        type: "text",
        message: msg,
        overlay: overlay,
        // forbidClick: true,
        closeOnClick: true,
        closeOnClickOverlay: true,
        duration: duration,
        position: "bottom",
        onOpened: () => {
          if (onOpened !== null) {
            onOpened();
          } else {
            return true;
          }
        },
        onClose: () => {
          if (onClose !== null) {
            onClose();
          } else {
            return true;
          }
        },
      });
    }, 100);
  },

  /**
   * 操作成功提示
   * @param {string} msg 消息文字
   * @param {number} duration 展示时间
   * @param {Function} onOpened 展示后回调
   * @param {Function} onClose 结束后回调
   */
  success: (
    msg = "操作成功!",
    duration = 2000,
    onOpened = null,
    onClose = null
  ) => {
    setTimeout(() => {
      Toast.success({
        message: msg,
        overlay: true,
        //   forbidClick: true,
        closeOnClick: true,
        closeOnClickOverlay: true,
        duration: duration,
        onOpened: () => {
          if (onOpened !== null) {
            onOpened();
          }
        },
        onClose: () => {
          if (onClose !== null) {
            onClose();
          }
        },
      });
    }, 100);
  },

  /**
   * 操作失败提示
   * @param {string} msg 消息文字
   * @param {number} duration 展示时间
   * @param {Function} onOpened 展示后回调
   * @param {Function} onClose 结束后回调
   */
  fail: (
    msg = "操作失败!",
    duration = 3000,
    onOpened = null,
    onClose = null
  ) => {
    setTimeout(() => {
      Toast.fail({
        message: msg,
        overlay: true,
        //   forbidClick: true,
        closeOnClick: true,
        closeOnClickOverlay: true,
        duration: duration,
        onOpened: () => {
          if (onOpened !== null) {
            onOpened();
          }
        },
        onClose: () => {
          if (onClose !== null) {
            onClose();
          }
        },
      });
    }, 100);
  },

  /**
   * 消息提示弹窗
   * @param {string} text 提示文字
   * @param {Function} beforeClose 点击关闭之后的回调函数
   */
  alert: (text = "消息提示!", beforeClose = null) => {
    Dialog.alert({
      message: text,
      beforeClose: () => {
        if (beforeClose !== null) {
          beforeClose();
        }
      },
    }).then((r) => {
      console.log(r);
    });
  },

  /**
   * 消息确认弹窗
   * @param title
   * @param {string} text 提示文字
   * @param beforeClose
   */
  confirm: (title = "系统提示", text = "消息提示!", beforeClose = null) => {
    Dialog.confirm({
      title: title,
      message: text,
    })
      .then(() => {
        if (beforeClose !== null) {
          beforeClose();
        }
      })
      .catch(() => {
        return true;
      });
  },

  /**
   * 打开Loading
   * @param {string} msg 提示文字
   * @returns
   */
  loading: (msg = "努力加载中...") => {
    return Toast.loading({
      message: msg,
      overlay: true,
      forbidClick: true,
      duration: 0,
    });
  },

  // 关闭Loading
  hideLoading: (loading = null) => {
    if (loading !== null) {
      loading.clear(); //关闭Loading
    } else {
      Toast.clear(); //关闭所有提示
    }
  },

  /**
   * 播放一段音频
   * @param {string} audioPath 音频路径
   */
  playAudio(audioPath) {
    let audio = new Audio();
    audio.src = audioPath;
    audio.play().then((r) => {
      console.log(r);
    });
  },

  /**
   * 设置水印
   * @param text1
   * @param text2
   */
  setWatermark: (text1, text2) => {
    watermark.set(text1, text2);
  },

  /******************** 路由助手 ********************/

  /**
   * 返回上一页或几页
   */
  backView: () => {
    if (window.history.length <= 1) {
      router.push({ path: "/", replace: true }).then((r) => {
        console.log(r);
      }); //跳转到首页
    } else {
      router.go(-1);
    }
  },

  /**
   * 路由跳转
   * @param path 路由地址
   * @param delayed 延时
   * @param replace 替换当前路由
   * @param query 参数
   */
  routerGo: (path, delayed = 0, replace = false, query = null) => {
    setTimeout(() => {
      router.push({ path: path, replace: replace, query: query }).then((r) => {
        console.log(r);
      });
    }, delayed);
  },

  /**
   * 获取查询参数
   */
  getRouteQuery: () => {
    const route = useRoute();
    return route.query || route.params;
  },
};

export default {
  version: "1.0.0",
  author: "ZhiKeCloud",
  install(Vue) {
    // toast Fn
    Vue.config.globalProperties.$utils = Utils;
  },
};
