/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-01 01:38:47 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-04-03 17:42:56
 * @Description: 公共API接口
 * @FilePath: \src\api\module\global.js
 */

// 请求方法封装
import http from "../api.js";

//获取菜单配置
export const getRouterAndNavBarConfigApi = (params) =>
  http.get("/v1/Menu/GetAppRouterAndNavBarConfig", params);
