/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 18:38:21
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-05-25 09:11:14
 * @Description: 请输入文件描述信息
 * @FilePath: \src\router\routes.js
 */

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    id: "a6s28w1g74",
    title: "Main",
    name: "Main",
    path: "/",
    component: () => import("@/components/main/main.vue"),
    children: [
      //首页
      {
        name: "Home", //命名路由
        path: "Home", //路径
        component: () => import(`@/views/Home/Home.vue`), //命名视图组件
        meta: {
          id: "home", //对应的菜单ID
          verify: 0, //认证标识
          showTabBar: 1, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //个人信息页
      {
        name: "PersonalInfo", //命名路由
        path: "PersonalInfo", //路径
        component: () => import(`@/views/PersonalInfo/PersonalInfo.vue`), //命名视图组件
        meta: {
          id: "PersonalInfo", //对应的菜单ID
          verify: 0, //认证标识
          showTabBar: 1, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //关于页
      {
        name: "About", //命名路由
        path: "About", //路径
        component: () => import(`@/views/About/About.vue`), //命名视图组件
        meta: {
          id: "About", //对应的菜单ID
          verify: 0, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //账号安全
      {
        name: "AccountSecurity", //命名路由
        path: "AccountSecurity", //路径
        component: () => import(`@/views/AccountSecurity/AccountSecurity.vue`), //命名视图组件
        meta: {
          id: "AccountSecurity", //对应的菜单ID
          verify: 1, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //修改密码
      {
        name: "EditPassword", //命名路由
        path: "EditPassword", //路径
        component: () => import(`@/views/AccountSecurity/EditPassword.vue`), //命名视图组件
        meta: {
          id: "EditPassword", //对应的菜单ID
          verify: 1, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //修改用户名
      {
        name: "EditUsername", //命名路由
        path: "EditUsername", //路径
        component: () => import(`@/views/AccountSecurity/EditUsername.vue`), //命名视图组件
        meta: {
          id: "EditUsername", //对应的菜单ID
          verify: 1, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //修改昵称
      {
        name: "EditNickname", //命名路由
        path: "EditNickname", //路径
        component: () => import(`@/views/AccountSecurity/EditNickname.vue`), //命名视图组件
        meta: {
          id: "EditNickname", //对应的菜单ID
          verify: 1, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //修改手机号码
      {
        name: "EditPhone", //命名路由
        path: "EditPhone", //路径
        component: () => import(`@/views/AccountSecurity/EditPhone.vue`), //命名视图组件
        meta: {
          id: "EditPhone", //对应的菜单ID
          verify: 1, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //修改邮箱
      {
        name: "EditEmail", //命名路由
        path: "EditEmail", //路径
        component: () => import(`@/views/AccountSecurity/EditEmail.vue`), //命名视图组件
        meta: {
          id: "EditEmail", //对应的菜单ID
          verify: 1, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //客服
      {
        name: "KeFu", //命名路由
        path: "KeFu", //路径
        component: () => import(`@/views/KeFu/KeFu.vue`), //命名视图组件
        meta: {
          id: "KeFu", //对应的菜单ID
          verify: 0, //认证标识
          showTabBar: 1, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //设置
      {
        name: "Setting", //命名路由
        path: "Setting", //路径
        component: () => import(`@/views/PersonalInfo/Setting.vue`), //命名视图组件
        meta: {
          id: "Setting", //对应的菜单ID
          verify: 1, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //消息通知设置
      {
        name: "MessageConfig", //命名路由
        path: "MessageConfig", //路径
        component: () => import(`@/views/MessageConfig/MessageConfig.vue`), //命名视图组件
        meta: {
          id: "MessageConfig", //对应的菜单ID
          verify: 1, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },

      //仓库地址
      {
        name: "WhAddress", //命名路由
        path: "WhAddress", //路径
        component: () => import(`@/views/WhAddress/WhAddress.vue`), //命名视图组件
        meta: {
          id: "WhAddress", //对应的菜单ID
          verify: 0, //认证标识
          showTabBar: 0, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      },
    ],
  },
  {
    id: "login",
    title: "Login",
    name: "Login",
    path: "/Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    id: "wechatLogin",
    title: "WechatLogin",
    name: "WechatLogin",
    path: "/WechatLogin",
    component: () => import("@/views/WechatLogin.vue"),
  },
  {
    id: "signup",
    title: "Signup",
    name: "Signup",
    path: "/Signup",
    component: () => import("@/views/Signup.vue"),
  },
  {
    id: "Vconsole",
    title: "Vconsole",
    name: "Vconsole",
    path: "/Vconsole",
    component: () => import("@/views/Vconsole.vue"),
  },
  {
    id: "Err",
    title: "Err",
    name: "Err",
    path: "/Err",
    component: () => import("@/views/err.vue"),
  },
];

export default routes;
