/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 17:57:55
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-05-25 11:28:19
 * @Description: 请输入文件描述信息
 * @FilePath: \src\store\index.js
 */
import { createStore } from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import UserModule from "./UserModule";
import RouterModule from "./RouterModule";
import MenuModule from "./MenuModule";
import system_config from "@/config";

const state = {
  version: system_config.app_version, //系统版本
  title: system_config.app_name, //标题
  title_prefix: system_config.abbrev_name, //标题前缀
  is_wechat: false, //是否为微信环境
  permission_list: [], //权限列表
  voice_version: "mandarin_version_1", //语音助手设置
  error_cache: {}, //全局错误缓存
};

const store = createStore({
  //全局状态
  state,
  mutations,
  actions,
  //模块
  modules: {
    UserModule,
    RouterModule,
    MenuModule,
  },
});

//导出
export default store;
