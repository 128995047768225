/*
 * @Author: 智客云网络科技
 * @Date: 2022-04-18 15:06:06
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-05-25 11:29:25
 * @Description: 微信公众号
 * @FilePath: \src\wechat\index.js
 */

import wx from "weixin-js-sdk"; //微信js-sdk库
import { getJsSdkSignPackageApi } from "@/api/module/wechat";
import system_config from "@/config";

//微信js-sdk方法封装
const Wechat = {
  /**
   * 分享小图
   */
  wxfxImg: system_config.wx_share_img,

  /**
   * 服务器地址
   */
  base_url: system_config.base_url,

  /**
   * 应用标题
   */
  app_title: system_config.app_name,

  /**
   * 当前页面url
   */
  url: "",

  /**
   * 接口权限
   */
  jsApiList: [
    //分享接口
    "updateAppMessageShareData", //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
    "updateTimelineShareData", //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
    "onMenuShareTimeline", //获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）[PC端使用 新接口PC端无法使用]
    "onMenuShareAppMessage", //获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）[PC端使用 新接口PC端无法使用]
    // "onMenuShareQQ", //获取“分享到QQ”按钮点击状态及自定义分享内容接口（即将废弃）
    // "onMenuShareWeibo", //获取“分享到腾讯微博”按钮点击状态及自定义分享内容接口
    // "onMenuShareQZone", //获取“分享到QQ空间”按钮点击状态及自定义分享内容接口（即将废弃）

    // 音频接口
    // "startRecord", //开始录音接口
    // "stopRecord", //停止录音接口
    // "onVoiceRecordEnd", //监听录音自动停止接口
    // "playVoice", //播放语音接口
    // "pauseVoice", //暂停播放接口
    // "stopVoice", //停止播放接口
    // "onVoicePlayEnd", //监听语音播放完毕接口
    // "uploadVoice", //上传语音接口
    // "downloadVoice", //下载语音接口

    // 图像接口
    // "chooseImage", //拍照或从手机相册中选图接口
    // "previewImage", //预览图片接口
    // "uploadImage", //上传图片接口
    // "downloadImage", //下载图片接口

    // 智能接口
    // "translateVoice", //识别音频并返回识别结果接口

    // 设备信息
    // "getNetworkType", //获取网络状态接口

    // 地理位置
    // "openLocation", //使用微信内置地图查看位置接口
    // "getLocation", //获取地理位置接口

    // 官方没写 不知道是啥东西
    // "hideOptionMenu", //
    // "showOptionMenu", //

    // 界面操作
    // "hideMenuItems", //批量隐藏功能按钮接口
    // "showMenuItems", //批量显示功能按钮接口
    // "hideAllNonBaseMenuItem", //隐藏所有非基础按钮接口
    // "showAllNonBaseMenuItem", //显示所有功能按钮接口
    // "closeWindow", //关闭当前网页窗口接口

    // 微信扫一扫
    // "scanQRCode", //调起微信扫一扫接口

    // 微信支付
    // "chooseWXPay", //发起一个微信支付请求

    // 微信小店
    // "openProductSpecificView", //跳转微信商品页接口

    // 微信卡券
    // "addCard", //批量添加卡券接口
    // "chooseCard", //拉取适用卡券列表并获取用户选择信息
    // "openCard", //查看微信卡包中的卡券接口
  ],

  /**
   * 初始化
   */
  init: (url) => {
    //先获取签名
    getJsSdkSignPackageApi({
      url: url,
    })
      .then((res) => {
        //初始化js-sdk
        wx.config({
          debug: false, // 开启调试模式
          appId: res.data.app_id, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonce_str, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: Wechat.jsApiList, // 必填，需要使用的JS接口列表
        });

        Wechat.url = url;
      })
      .catch((err) => {
        //静默失败
        console.log("JsSDK初始化失败", err);
        return false;
      });

    //通过error接口处理失败验证
    wx.error((res) => {
      console.log("微信JsSDK配置失败", res);
    });

    // config信息验证后会执行ready方法
    wx.ready(() => {});
  },

  // config信息验证后会执行ready方法
  ready: (callback = null) => {
    wx.ready(() => {
      if (callback) {
        callback();
      }
    });
  },

  //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
  appMessageShare: (title, desc = "") => {
    let imgUrl = Wechat.base_url + Wechat.wxfxImg;
    wx.ready(() => {
      let option = {
        title: Wechat.app_title + title, // 分享标题
        desc: desc, // 分享描述
        link: Wechat.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
        },
      };
      if (Wechat.isPc()) {
        wx.onMenuShareAppMessage(option);
      } else {
        wx.updateAppMessageShareData(option);
      }
    });
  },

  //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
  timelineShare: (title) => {
    let imgUrl = Wechat.base_url + Wechat.wxfxImg;
    wx.ready(() => {
      let option = {
        title: Wechat.app_title + title, // 分享标题
        link: Wechat.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
        },
      };
      if (Wechat.isPc()) {
        wx.onMenuShareTimeline(option);
      } else {
        wx.updateTimelineShareData(option);
      }
    });
  },

  isPc: () => {
    const userAgentInfo = navigator.userAgent;
    const Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];
    let flag = true;
    for (let i = 0; i < Agents.length; i++) {
      if (userAgentInfo.indexOf(Agents[i]) !== -1) {
        flag = false;
        break;
      }
    }
    return flag;
  },
};

export default {
  version: "1.0.0",
  author: "ZhiKeCloud",
  install(Vue) {
    // toast Fn
    Vue.config.globalProperties.$wx = Wechat;
  },
};
