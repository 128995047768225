/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 17:57:55
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-04-24 09:17:30
 * @Description: 请输入文件描述信息
 * @FilePath: \src\router\index.js
 */

import { toRaw } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import staticRoute from "./routes"; //静态路由
import { getRouterAndNavBarConfigApi } from "@/api/module/Global"; //公共Api接口

//定义404路由
const Route404 = {
  id: "page404",
  title: "404",
  name: "404",
  path: "/:catchAll(.*)",
  component: () => import(`@/views/404.vue`),
};

const router = createRouter({
  history: createWebHistory(),
  routes: staticRoute,
});

/**
 * 注册一个全局前置守卫
 * to:即将要进入的目标路由对象
 * from:当前导航正要离开的路由
 * next:一定要调用该方法来 resolve 这个钩子
 */
router.beforeEach((to, from, next) => {
  setShowTab(to); //设置当前显示的Tab
  store.commit("MenuModule/setTabBarStatus", to.meta.showTabBar); //设置TabBar显示状态
  //登录界面登录成功之后，会把用户信息及Token保存在localStorage
  let Token = localStorage.getItem("token"); //仅登录成功后才存在Token
  if (to.path === "/Login") {
    //如果是访问登录界面并且Token存在 则代表已登录过 跳转到主页
    //否则继续进入登录页面
    if (Token) {
      next({ path: "/Home", replace: true });
    } else {
      next();
    }
  } else if (to.path === "/Err") {
    //访问错误页面 直接放行
    next();
  } else if (to.meta.verify) {
    //如果访问的不是登录界面，且路由需要登录
    //判断Token是否存在 不存在代表未登录，跳转到登录
    //否则如果Token存在则代表一登录 写入信息后跳转到目标页面
    if (!Token) {
      next({ path: "/Login", replace: true });
    } else {
      _SetUserInfo(); //写入用户信息
      _LoadMenuAndRoutes(to, from, next); //添加动态菜单和路由后直接跳转
    }
  } else {
    //不需要登录 写入信息后跳转到目标页面
    _SetUserInfo(); //写入用户信息
    _LoadMenuAndRoutes(to, from, next); //添加动态菜单和路由后，直接跳转
  }
});

/**
 * 加载菜单和路由
 * @param {Object} to
 * @param {Object} from
 * @param {function} next
 * @returns
 */
function _LoadMenuAndRoutes(to, from, next) {
  //如果路由和菜单已加载则调用next();
  if (store.state["MenuModule"]["navBarStatus"] && store.state["RouterModule"]["routerStatus"]) {
    next();
    return;
  }
  
  //优先从本地sessionStorage获取路由和导航菜单配置
  // let routerConfig = localStorage.getItem("RouterConfig");
  // let navBarConfig = localStorage.getItem("NavBarConfig");
  //
  // routerConfig = null;
  // navBarConfig = null;
  
  // if (routerConfig && navBarConfig) {
  //   //写入路由配置
  //   let set_router_config = _SetRouterConfig(JSON.parse(routerConfig));
  //
  //   //写入导航菜单配置
  //   let set_nav_bar_config = _SetNavBarConfig(JSON.parse(navBarConfig));
  //
  //   if (set_router_config && set_nav_bar_config) {
  //     next({ ...to, replace: true });
  //   } else {
  //     next({ path: "/" });
  //   }
  // } else {
  //本地sessionStorage获取不到，从服务器拉取
  getRouterAndNavBarConfigApi()
    .then((res) => {
      let config = res.data;
      //写入路由配置
      let set_router_config = _SetRouterConfig(config.routerConfig);
      //写入导航菜单配置
      let set_nav_bar_config = _SetNavBarConfig(config.navBarConfig);
      if (set_router_config && set_nav_bar_config) {
        next({ ...to, replace: true });
      } else {
        next({ path: "/" });
      }
    })
    .catch((res) => {
      console.log(res);
    });
  // }
}

/**
 * 设置路由配置
 * @param {object} routerConfig 路由配置
 */
function _SetRouterConfig(routerConfig) {
  //验证数据类型
  if (typeof routerConfig !== "object") {
    return false;
  }
  
  //检查列表是否为空
  if (routerConfig.length > 0) {
    for (let i = 0; i < routerConfig.length; i++) {
      let data = routerConfig[i]; //取出数据
      
      //定义路由数据
      let routers = {
        name: data.route, //命名路由
        path: data.route, //路径
        component: () => import(`@/views/${data.uri}`), //命名视图组件
        // components: "", //命名视图组件
        // redirect: "", //重定向路径
        // alias: "", //别名
        // children: "", //嵌套路由
        // caseSensitive: false, //匹配规则是否大小写敏感?(默认 false)
        // pathToRegexpOptions: "", //编译正则的选项
        meta: {
          id: data.menu_id, //对应的菜单ID
          verify: data.verify, //认证标识
          showTabBar: data.showTabBar, //是否显示TabBar
        }, //路由元信息 使用$route.meta.属性可以获取
      };
      
      //添加路由
      router.addRoute("Main", routers);
    }
  }
  
  router.addRoute(Route404); //插入404路由
  store.commit("RouterModule/setOriginalRoutes", routerConfig); //写入原始路由表
  store.commit("RouterModule/setRoutes", router.getRoutes()); //写入路由表
  store.commit("RouterModule/setRouterStatus", true); //设置路由为已加载状态
  localStorage.setItem("RouterConfig", JSON.stringify(routerConfig)); //路由数据写入localStorage
  
  return true;
}

/**
 * 设置导航菜单配置
 * @param {object} navBarConfig 导航菜单配置
 */
function _SetNavBarConfig(navBarConfig) {
  //验证数据类型
  if (typeof navBarConfig !== "object") {
    return false;
  }
  
  let BuildNavBarCache = []; //导航菜单构建缓存
  
  //检查列表是否为空
  if (navBarConfig.length > 0) {
    for (let i = 0; i < navBarConfig.length; i++) {
      let data = navBarConfig[i]; //取出数据
      
      //定义菜单数据
      let menu = {
        id: data.menu_id, //菜单ID
        show: data.show, //菜单是否显示
        verify: data.verify, //菜单是否需要认证
        name: data.name, //菜单显示名称
        route: data.route, //菜单对应路由名
        icon: data.icon || "", //菜单图标
      };
      
      BuildNavBarCache.push(menu); //导航菜单构建缓存
    }
  }
  
  store.commit("MenuModule/setOriginalNavBarConfig", navBarConfig); //写入原始菜单配置
  store.commit("MenuModule/setNavBarConfig", BuildNavBarCache); //写入菜单配置
  store.commit("MenuModule/setNavBarStatus", true); //设置菜单为已加载状态
  localStorage.setItem("NavBarConfig", JSON.stringify(navBarConfig)); //菜单数据写入localStorage
  
  return true;
}

/**
 * 向Vuex内写入用户信息
 */
function _SetUserInfo() {
  //设置用户信息
  let userinfo = localStorage.getItem("userinfo");
  let token = localStorage.getItem("token");
  
  if (userinfo && token) {
    let uf = JSON.parse(userinfo);
    store.commit("UserModule/setUserName", uf.username); //用户名
    store.commit("UserModule/setNickName", uf.nickname); //用户昵称
    store.commit("UserModule/setSign", uf.sign); //个签
    store.commit("UserModule/setAvatar", uf.avatar); //头像
    store.commit("UserModule/setLoginStatus", true); //写入登录态
    store.commit("UserModule/setToken", token); //token
    
    return true;
  }
  
  return false;
}

/**
 * 设置当前显示的Tab
 * @param {object} to
 * @returns
 */
function setShowTab(to) {
  if (to.name === undefined) {
    return false;
  }
  
  let tabs = toRaw(store.state.MenuModule.navBarConfig);
  let tab_index = arrayGetIndex(tabs, "route", to.name);
  if (tab_index === undefined) {
    return false;
  }
  
  let tab = tabs[tab_index];
  if (tab === undefined) {
    return false;
  }
  
  store.commit("MenuModule/setShowTab", tab);
}

/**
 * 根据指定数据从数组里获取index
 * @param {*} arr 数组
 * @param {*} prop 数据键名
 * @param {*} value 标定数据
 * @returns
 */
function arrayGetIndex(arr, prop, value) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][prop] === value) {
      return i;
    }
  }
  return null;
}

export default router;
