/*
 * @Author: 智客云网络科技
 * @Date: 2021-03-31 12:00:55 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-05-25 11:28:44
 * @Description: axios 实例，拦截器配置以及错误处理
 * @FilePath: \src\api\request.js
 */

import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Toast } from "vant";
import system_config from "@/config";

let Promise = require("es6-promise").Promise;

//loading对象
let loading;

//请求超时时长
const timeout = 30 * 1000;

//显示loading
let showloading = true;

//加载中的请求个数
let loadingCount = 0;

//请求头
const contentType = "application/x-www-form-urlencoded";

//服务器地址
const baseUrl = system_config.base_url;

//创建axios实例
const request = axios.create({
  baseURL: baseUrl,
  timeout: timeout,
  showloading: showloading,
  headers: {
    "Content-Type": contentType,
  },
  responseType: "json", //服务器响应的数据类型
});

//请求拦截器
request.interceptors.request.use(
  (config) => {
    //打开looading
    if (config.showloading === true) {
      showLoading();
    }
    //写入Token 如果存在的话
    const X_Token = store.state.UserModule.token;
    if (X_Token) {
      config.headers["X-Token"] = X_Token;
    }
    //写入请求语言
    const Client_language = store.state.UserModule.language;
    if (Client_language) {
      config.headers["X-Language"] = Client_language;
    }
    return config;
  },
  (error) => {
    closeLoading();
    return Promise.reject(error);
  },
);

// 响应拦截器
request.interceptors.response.use(
  (res) => {
    //关闭Loading
    closeLoading();
    
    //返回码是200时执行
    if (res.status === 200) {
      //返回数据无效
      if (!res.data) {
        return Promise.reject({
          code: 204,
          err_msg: "返回数据无效，请检查网络或联系管理员!",
        });
      }
      
      //返回成功
      let statec = res.data.code;
      if (statec === 200) {
        return Promise.resolve(res.data);
      }
      
      //全局跳转方法
      if (statec === -9) {
        window.location.href = res.data.data;
        return;
      }
      
      //用户未登录
      if (statec === -2) {
        //清除本地缓存
        window.localStorage.clear();
        window.sessionStorage.clear();
        //跳转登录
        router.push({ path: "/Login" }).then((r) => {
          console.log(r);
        });
        return;
        // return Promise.reject(res.data);
      }
      
      //Token无效
      if (statec === -3) {
        //清除本地缓存
        window.localStorage.clear();
        window.sessionStorage.clear();
        //跳转登录
        router.push({ path: "/Login" }).then((r) => {
          console.log(r);
        });
        return Promise.reject(res.data);
      }
      
      //Token无效
      //JWT算法无效
      //签名无效
      //负载无效
      //签发日期无效
      //NBF时间内
      if (107002 <= statec && statec <= 107007) {
        //清除本地缓存
        window.localStorage.clear();
        window.sessionStorage.clear();
        //跳转登录
        router.push({ path: "/Login" }).then((r) => {
          console.log(r);
        });
        return Promise.reject(res.data);
      }
      
      //账号不存在 可能是账号已被删除或注销
      if (statec === 101024) {
        //清除本地缓存
        window.localStorage.clear();
        window.sessionStorage.clear();
        //跳转登录
        router.push({ path: "/Login" }).then((r) => {
          console.log(r);
        });
        return Promise.reject(res.data);
      }
      
      //抛出错误
      return Promise.reject(res.data);
    } else {
      console.log(res);
    }
  },
  (err) => {
    // 这里是返回状态码不为200时候的错误处理
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.err_msg = "请求错误";
          break;
        case 401:
          err.err_msg = "未授权，请登录";
          break;
        case 403:
          err.err_msg = "无访问权限";
          break;
        case 404:
          err.err_msg = "接口请求错误";
          break;
        case 405:
          err.err_msg = "接口请求错误";
          break;
        case 408:
          err.err_msg = "请求超时";
          break;
        case 500:
          err.err_msg = "服务器内部错误";
          break;
        case 501:
          err.err_msg = "服务未实现";
          break;
        case 502:
          err.err_msg = "网关错误";
          break;
        case 503:
          err.err_msg = "服务不可用";
          break;
        case 504:
          err.err_msg = "网关超时";
          break;
        case 505:
          err.err_msg = "HTTP版本不受支持";
          break;
        default:
          closeLoading();
          return Promise.reject(err);
      }
      
      closeLoading();
      return Promise.reject({
        code: err.response.status,
        err_msg: err.response.err_msg || err.err_msg,
      });
    }
    
    console.log(err);
    closeLoading();
    if (err.config.url !== "/v1/Wechat/GetJsSdkSignPackage") {
      store.commit("setErrorCache", {
        message: err.message,
        stack: err.stack,
        server: err.config.baseURL,
        uri: err.config.url,
      });
      console.log(store.state.error_cache);
      router.push({ path: "/Err" });
    }
    return Promise.reject({
      code: -0,
      err_msg: "服务请求错误,请检查网络或联系管理员!",
    });
  },
);

//显示loading
function showLoading() {
  if (loadingCount === 0) {
    loading = Toast.loading({
      message: "努力加载中……",
      overlay: true,
      forbidClick: true,
      duration: 0,
    });
  }
  loadingCount++;
}

//关闭loading
function closeLoading() {
  loadingCount--;
  if (loadingCount === 0) {
    if (loading !== null) {
      loading.clear(); //关闭Loading
    } else {
      Toast.clear(); //关闭所有提示
    }
  }
}

export default request;
