/*
 * @Author: 智客云网络科技
 * @Date: 2022-04-18 15:43:34
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-04-18 17:40:00
 * @Description: 请输入文件描述信息
 * @FilePath: \src\api\module\wechat.js
 */

import http from "../api.js";

// 获取微信JS-SDK签名参数
export const getJsSdkSignPackageApi = (params) =>
  http.get("/v1/Wechat/GetJsSdkSignPackage", params);
