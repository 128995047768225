/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 17:57:55
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-05-25 11:29:51
 * @Description: 请输入文件描述信息
 * @FilePath: \src\main.js
 */
import "babel-polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import utils from "@/utils";
import wx from "@/wechat";
import Vant from "vant";
import { Lazyload } from "vant";
import Vconsole from "vconsole";
import "amfe-flexible"; //h5rem适配
import "vant/lib/index.css";
import "./globa.css";
import system_config from "@/config";

//Vconsole
if (
  process.env.NODE_ENV !== "production" ||
  localStorage.getItem("Vconsole") === "1"
) {
  new Vconsole();
}

const app = createApp(App);

//配置写入全局
app.config.globalProperties.$config = system_config;

app.use(Vant);
app.use(Lazyload);
app.use(utils);
app.use(wx);
app.use(store);
app.use(router);
app.mount("#app");
