/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-13 20:38:32 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-05-23 12:55:48
 * @Description: 更改store中状态的唯一方法
 * @FilePath: \src\store\mutations.js
 */

const mutations = {
  //设置微信环境
  setIsWechat: (state) => {
    state.is_wechat = true;
  },

  //设置语音助手语言版本
  setVoiceVersion: (state, payload) => {
    state.voice_version = payload;
  },

  //设置全局错误缓存
  setErrorCache: (state, payload) => {
    state.error_cache = payload;
  },
};

export default mutations;
